const SectionsType = {
    "Configuration DPI": {id: ControllersEnum.DpiConfig},
    "Configuration GUI": {id: ControllersEnum.Dpiui2Config},
    "Configuration QoE": {id: ControllersEnum.QoEStorConfig},
    "Configuration Balancer": {id: ControllersEnum.BalancerConfig},
    "Tariff plans": {id: ControllersEnum.Tariffs},
    "Subscribers and services": {id: ControllersEnum.Subscribers, path:"/sv_tab=SubscribersTab"},
    "Protocol prioritization": {id: ControllersEnum.DpiDscpConfig},
    "Priority for ASN": {id: ControllersEnum.DpiAsnDscpConfig},
    "Black and white lists": {id: ControllersEnum.Services, path:"/tab=4"}
}

const SectionsTypeHref = {
    "Configuration DPI": "#DpiConfig",
    "Configuration GUI": "#Dpiui2Config",
    "Configuration QoE": "#QoEStorConfig",
    "Configuration Balancer": "#BalancerConfig",
    "Tariff plans": "#Tariffs",
    "Subscribers and services": "#Subscribers/sv_tab=SubscribersTab",
    "Protocol prioritization": "#DpiDscpConfig",
    "Priority for ASN": "#DpiAsnDscpConfig",
    "Black and white lists": "#Services/tab=4"
}

const FunctionsList = {
    "Subscribers and services": "SubscribersContentPopup",
    "Tariff plans": "GetTariffPlansPopupBlocks",
    "Protocol prioritization": "GetConfigurationPopupBlocks",
    "Priority for ASN": "GetPriorityForAsnPopupBlocks",
    "Black and white lists": "GetBlackWhiteListsPopupBlocks"
}

const TariffPlanList = {
    "title": "Tariff name",
    "individual": "Tariff type",
    "status": "Status",
    "schedule_enable": "Schedule",
    "0": "Schedule Disabled",
    "1": "Schedule Enabled",
}

const TariffPlanTypeList = {
    "0": "Profile",
    "1": "Personal"
}

const TariffPlanStateList = {
    "0": "Disabled",
    "1": "Enabled"
}

const BlackWhiteList = {
    "title": "List name",
    "list_type": "List type",
    "redirect": "URL to redirect",
    "federal": "Use federal",
    "black_list": "Black list",
    "white_list": "White list",
    "true": "Federal Enabled",
    "false": "Federal Disabled",
    "sync_time_hours": "Synchronization period in hours",
    "sync_time_status": "Synchronization status",
    "disabled": "Disabled",
    "enabled": "Enabled"
}

const BlackWhiteListItems = {
    "1": "Enabled",
    "0": "Disabled",
    "enabled": "Status",
    "type": "Type",
    "uri": "Resource"
}

const ContentTypeCodeList = {
    "Black and white lists": BlackWhiteList,
    "Tariff plans": TariffPlanList
}

const SubscribersFields = {
    "login": "Login",
    "ip": "Ip",
    "auth_status": "Auth status",
    "auth_expired": "Auth expired",
    "policing": "Policing",
    "services_profiles": "Services profiles",
    "services": "Services",
    "type": "Session type",
    "status": "Session status",
    "mac": "MAC address",
    "vlan": "VLAN or QinQ",
    "expired": "Expiration time",
    "iface": "in_dev interface index",
    "disable_local_connect": "Disable local connect for subscriber",
    "out_vlan": "Output VLAN=NN",
    "out_mac": "Output MAC address",
    "subnet_mask": "IPv4 subnet mask",
    "opt82_circuit_id": "Opt82 CircuitId",
    "opt82_remote_id": "Opt82 RemoteId",
    "route_via": "'route via' property",
    "bind_type": "Bind type"
};


function UsersActionsLogTableWidget(Params) {
    var params = {
        id:Params&&Params.id?Params.id:webix.uid(),
        name:Params&&Params.name?Params.name:"UsersActionsLogTableWidget",
        sortDisabledColumns:["checkbox", "trash", "edit"],
        enableCheckboxRefresh:true,
        // showProgressWhenDataLoading:true,
        config:{
            method:function ()
            {
                return "GetUsersActionsLogs";
            },
            sort:{id:"update_time",dir:"desc"}
        },
        columns: function () {
            var cols = [];

            cols.push.apply(cols, [
                {
                    id: "hardware_id",
                    header: [
                        LangHelper.GetString("UsersActionsLog","Hardware name"),
                        {
                            content: "multiSelectFilter",
                            options: App.DicsManager.GetDicAsArrayWithEmpty("HardwaresDic"),
                            inputConfig: {suggest: {fitMaster: false}}
                        }
                    ],
                    editor: "multiselect",
                    options: App.DicsManager.GetDicAsArrayWithEmpty("HardwaresDic"),
                    template: function(Obj) {
                        if(Obj.hardware_id) {
                            let hardwareInfo = App.DicsManager.GetDic("HardwaresDic");

                            return hardwareInfo && hardwareInfo[Obj.hardware_id] ? hardwareInfo[Obj.hardware_id].value : "";
                        }
                    },
                    fillspace: true,
                    width: 100
                },
                {
                    id: "type_code",
                    header: [
                        LangHelper.GetString("UsersActionsLog","Section"),
                        {
                            content: "multiSelectFilter",
                            options:function ()
                            {
                                var r = [];

                                Object.keys(UsersActionsLogTypeCodes).forEach(function (Key)
                                {
                                    r.push({id:Key, value:UsersActionsLogTypeCodes[Key].value})
                                });

                                return r;
                            }(),
                            inputConfig: {suggest: {fitMaster: false}}
                        }
                    ],
                    template: function(Obj) {

                        let diff = JSON.parse(Obj.differences);

                        if(diff && typeof diff === 'object' && diff.hasOwnProperty("section")){
                            let href = diff.section
                            const [sectionName, _] = diff.section.split("/").map((item) => item.replace("#", ""));
                            return `<a href="${href}" onclick="return false" style="cursor:pointer">${UsersActionsLogNameSection[sectionName].value}</a>`;
                        }
                        let type = SectionsTypeHref[Obj.type_code] ? SectionsTypeHref[Obj.type_code] : "";

                        if (type) {
                            return `<a href="${type}" onclick="return false" style="cursor:pointer">${UsersActionsLogTypeCodes[Obj.type_code].value}</a>`;
                        } else {

                            return Obj.type_code;
                        }
                    },
                    fillspace: true,
                    width: 150
                },
                {
                    id: "action",
                    header: [
                        LangHelper.GetString("UsersActionsLog", "Action"),
                        {
                            content: "multiSelectFilter",
                            options:function ()
                            {
                                var r = [];

                                Object.keys(UsersActionsLogActions).forEach(function (Key)
                                {
                                    r.push({id:Key, value:UsersActionsLogActions[Key].value})
                                });

                                return r;
                            }(),
                            inputConfig: {suggest: {fitMaster: false}}
                        }
                    ],
                    template: function(Obj){
                        return UsersActionsLogActions[Obj.action].value;
                    },
                    fillspace: true,
                    width: 150
                },
                {
                    id: "differences",
                    header: [
                        LangHelper.GetString("UsersActionsLog","Differences"),
                        {content: "textFilter", placeholder:"&#xf002 "+LangHelper.GetString("All","Filter")}
                    ],
                    fillspace: true,
                    width: 55,
                    template: function (Obj) {
                        if (Obj.type_code === 'Tariff plans' || Obj.type_code === 'Subscribers and services' || Obj.type_code === 'Black and white lists')
                        {
                            let options = JSON.parse(Obj.differences);
                            let title = options.title;

                            if(Obj.action === "subscribers auth cleared"){
                                return LangHelper.GetString("UsersActionsLog", title);
                            }

                            if(Obj.action === "subscribers auth changed")
                                title = LangHelper.GetString("UsersActionsLog", options.title);

                            if(Obj.action === "search")
                                return CellEyeBtnTemplate();

                            return CellTextLinkTemplate(title);
                        }
                        return Obj.differences ? CellEyeBtnTemplate() : "";
                    }
                },
                {
                    id: "update_time",
                    header: [
                        LangHelper.GetString("UsersActionsLog","Update time"),
                        ""
                    ],
                    template: LocalTimeZone,
                    fillspace: true,
                    width: 150
                },
                {
                    id: "update_user",
                    header: [
                        LangHelper.GetString("UsersActionsLog","Update user"),
                        {content: "textFilter", placeholder:"&#xf002 " + LangHelper.GetString("All","Filter")}
                    ],
                    fillspace: true,
                    width: 150
                }
            ]);
            return cols;
        }(),
        rules:{},
        sort_map:{},
        defaultPageSize:100,
        multiselect:true,
        on:{}
        };

    if(Params&&Params.onItemClick)
    {
        params.on.onItemClick = function (Id, E, Node)
        {
            Params.onItemClick(Id, E, Node);
        };
    }

    if(Params&&Params.onCheck)
    {
        params.on.onCheck = function (Row, Column, State)
        {
            Params.onCheck(Row, Column, State);
        };
    }

    if(Params&&Params.onAfterSelect)
    {
        params.on.onAfterSelect = function (Selection, Preserve)
        {
            Params.onAfterSelect(Selection, Preserve);
        };
    }

    if(Params&&Params.onAfterUnSelect)
    {
        params.on.onAfterUnSelect = function (Selection)
        {
            Params.onAfterUnSelect(Selection);
        };
    }
    if(Params&&Params.onAfterFilterCustomHandler)
    {
        params.on.onAfterFilterCustomHandler = function ()
        {
            Params.onAfterFilterCustomHandler();
        };
    }

    if(Params&&Params.no_title)
        delete params.title;

    return new QoETableReportWidget(params);
}

function UsersActionsLogViewController(Params) {

    var _this = this;

    this.Id = "UsersActionsLog";

    this.maxDataGridRows = 100;

    this.ReportsModel = new UniReportsManager();

    this.PathParams = {};

    this.Views = {
        Widgets: {
            DateRangePicker: new QoEDateRangePickerWidget({id:this.Id + "DateRangePicker"})
        },
        ReportsWidgets:
            {
                UsersActionsLog: UsersActionsLogTableWidget({
                    id:this.Id + "UsersActionsLogTableWidget",
                    onItemClick:function (Obj) {

                        let Table = _this.Views.ReportsWidgets.UsersActionsLog.Views.Table;
                        let Item = Table.getSelectedItem();
                        let Node = Table.getItemNode(Obj);

                        if (Obj.column === "differences" && Item.differences) {
                            _this.ShowValueInfoPopup(Node, Item);
                        }
                        if (Obj.column === "type_code") {
                            if(Item.differences) {
                                let diff = JSON.parse(Item.differences);
                                if(diff && typeof diff === "object" && diff.hasOwnProperty("section")){
                                    const [id, path] = diff.section.split("/").map((item) => item.replace("#", ""));
                                    App.NavRouter.PresentView({id:id, path:path});
                                } else {
                                    App.NavRouter.PresentView(SectionsType[Item.type_code]);
                                }
                            } else {
                                App.NavRouter.PresentView(SectionsType[Item.type_code]);
                            }
                        }
                    }
                })
            }
    }

    this.Subscriptions = {};

    this.ViewConfig = [
        {
            cols: [
                AdministratorLeftSideBarView({id:this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title:LangHelper.GetString("AppView", "Users actions log"), title:LangHelper.GetString("AppView", "Users actions log")}),
                                {
                                    cols: [
                                        {
                                            rows: [
                                                {
                                                    view: "toolbar",
                                                    padding: 0,
                                                    margin: 0,
                                                    elements: [
                                                        this.Views.Widgets.DateRangePicker.ViewConfig,
                                                        {
                                                            gravity: 3
                                                        },
                                                        {
                                                            view: "button",
                                                            id: this.Id + "RefreshAllDataBtn",
                                                            type: "icon",
                                                            icon: "refresh",
                                                            css: "toolbar_round_btn",
                                                            width: 32,
                                                            tooltip: LangHelper.GetString("QoE", "Refresh"),
                                                            click: function (id, e, node)
                                                            {
                                                                _this.RefreshAllData();
                                                            }
                                                        }
                                                    ]
                                                },
                                                BlockHeaderTemplate({title:LangHelper.GetString("AppView", "Users actions log"), icon:"stack-overflow"}),
                                                this.Views.ReportsWidgets.UsersActionsLog.ViewConfig
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

}

UsersActionsLogViewController.prototype.ViewDidLoad = function (PathParams)
{
    var _this = this;

    this.PathParams = PathParams;

    webix.ready(function () {
        $$(_this.Id+"LeftSideBar").select(_this.Id);
    });

    this.BindAll(PathParams);

}

UsersActionsLogViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function (View)
    {
        if(View != "Widgets" && View != "ReportsWidgets")
            _this.Views[View] = $$(_this.Id + View)
    });

    Object.keys(this.Views.Widgets).forEach(function (WidgetKey)
    {
        var w = _this.Views.Widgets[WidgetKey];
        if(w.BindAll)
            w.BindAll(_this, _this.ViewModel);

        if(w.Bind)
            w.Bind(_this, _this.ViewModel);
    });

    Object.keys(this.Views.ReportsWidgets).forEach(function (WidgetKey)
    {
        var w = _this.Views.ReportsWidgets[WidgetKey];

        if(w.BindAll)
            w.BindAll(_this, _this.ReportsModel);

        if(w.Bind)
            w.Bind(_this, _this.ReportsModel);
    });

    this.GetAllPathParam();

    App.DicsManager.Refresh(
        {
            data: [
                DicsEnum.HardwaresDic, DicsEnum.WhiteListsProfilesDic, DicsEnum.BlackListsProfilesDic,
                DicsEnum.DDOSProtectionProfilesDic, DicsEnum.SessionPolicingProfilesDic, DicsEnum.CgNatProfilesDic, DicsEnum.PolicingProfilesDic,
                DicsEnum.MiniFireWallProfilesDic, DicsEnum.ClsfrCategoriesDic, DicsEnum.VaseListsDic
            ], filter:App.SessionManager.GetCurrentHardwareId()});

    this.Subscriptions.DicsSubscription = App.DicsManager.DicsUpdated.subscribe(function (value)
    {

        _this.Views.ReportsWidgets.UsersActionsLog.Views.Table.getColumnConfig("hardware_id").header[1].options = App.DicsManager.GetDicAsArrayWithEmpty(DicsEnum.HardwaresDic);

    });

    this.Subscriptions.DateRangePicker = this.Views.Widgets.DateRangePicker.ApplyStateSignal.subscribe(function (Signal)
    {
        if(Signal)
        {
            _this.RefreshAllData();
        }
    });

    _this.RefreshAllData();
}

UsersActionsLogViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        if(_this.Subscriptions[key])
            _this.Subscriptions[key].unsubscribe();
    });

    Object.keys(this.Views.Widgets).forEach(function (Widget)
    {
        var w = _this.Views.Widgets[Widget];
        if(w.Destroy)
            w.Destroy();
    });

    Object.keys(this.Views.ReportsWidgets).forEach(function (Widget)
    {
        var w = _this.Views.ReportsWidgets[Widget];
        if(w.Destroy)
            w.Destroy();
    });

    this.Views.Widgets.DateRangePicker.Destroy(this);
}

UsersActionsLogViewController.prototype.RefreshAllData = function ()
{
    var _this = this;

    var period = this.Views.Widgets.DateRangePicker.GetValue();

    var format =  webix.Date.dateToStr("%Y-%m-%d %H:%i:%s");

    if(!period.start || !period.end)
        return;

    var filter = {
        period:{
            id:"update_time",
            operator:"between",
            value:[format(Helper.DateTimeToUTC(period.start)), format(Helper.DateTimeToUTC(period.end))]
        }
    };

    var params = {filter:filter};

    _this.Views.ReportsWidgets.UsersActionsLog.RefreshData(0, params);

    _this.SetAllPathParam();
};

UsersActionsLogViewController.prototype.SetAllPathParam = function()
{
    var period = this.Views.Widgets.DateRangePicker.GetValue();

    if(period)
    {
        this.SetPathParam("period", period);
    }

    this.JustNavigate();
};

UsersActionsLogViewController.prototype.SetPathParam = function(Key, Val)
{
    App.NavRouter.SetPathParam(Key, Val, this);
};

UsersActionsLogViewController.prototype.JustNavigate = function()
{
    App.NavRouter.JustNavigate(this.Id, this.PathParams);
};

UsersActionsLogViewController.prototype.GetPathParam = function(Key)
{
    return App.NavRouter.GetPathParam(Key, this);
};

UsersActionsLogViewController.prototype.GetAllPathParam = function()
{
    try
    {
        var period = this.GetPathParam("period");
        this.Views.Widgets.DateRangePicker.SetValue(period,true);
    }
    catch(e){}
};

UsersActionsLogViewController.prototype.ShowValueInfoPopup = function (Node, Item)
{
    let _this = this;

    if(Item.action === "subscribers auth cleared")
        return;

    let diff = JSON.parse(Item.differences);

    let popupHeader = LangHelper.GetString("UsersActionsLog", Item.type_code);

    if(diff && typeof diff === 'object' && diff.hasOwnProperty("section")){
        let nameSection = diff.section.slice(1).split('/');

        if(UsersActionsLogNameSection[nameSection[0]]){
            popupHeader = UsersActionsLogNameSection[nameSection[0]].value + (UsersActionsLogNameSection[nameSection[0]].value === LangHelper.GetString("AppView", "Subscribers and services") ? " " : " (" + LangHelper.GetString("AppView", "Subscribers") + ")");
        } else {
            popupHeader = nameSection[0];
        }
    }

    const func = Item.type_code.includes("Configuration") ? "GetConfigurationPopupBlocks" : FunctionsList[Item.type_code];

    const popupBlocks = _this[func](Item);

    var context = webix.ui({
        view:"popup",
        id: this.Id + "ShowValueInfoPopup",
        width:600,
        height:500,
        body:{
            view: "form",
            borderless:true,
            rows:[
                {
                    view: "template",
                    template: popupHeader,
                    borderless: true,
                    type: "header"
                },
                {
                    rows: popupBlocks
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString("All","Close"),
                            click:function ()
                            {
                                context.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    context.show(Node, {pos:"right"});
};

UsersActionsLogViewController.prototype.GetConfigurationPopupBlocks = function (Item, Diff)
{
    let _this = this;

    let popupData = JSON.parse(Item.differences);

    let configChangesData = popupData.config_changes;

    let popupBlocks = []

    let popupTabbar = {
        view: "tabbar",
        id: "tabbar",
        multiview: true,
        options: [
            {value: LangHelper.GetString("UsersActionsLog", "Configuration changes"), id: 'ConfigChanges'},
        ]
    };
    let popupTabbarData = {
        cells: [
            _this.ConfigChangesPopupBlock(configChangesData)
        ]
    };

    popupBlocks.push(popupTabbar, popupTabbarData)

    return popupBlocks;
}

UsersActionsLogViewController.prototype.GetPriorityForAsnPopupBlocks = function (Item)
{
    let _this = this;

    let popupData = JSON.parse(Item.differences);

    let
        AsnIpv4Changes,
        AsnIpv6Changes,
        AsnDscpChanges;

    let
        options = [],
        cells = [];

    for (let key in popupData) {
        if (key === "asnv4_config_changes") {
            AsnIpv4Changes = popupData[key];
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "IPv4 and AS changes"), id: "AsnIpv4ChangesId"}
            );
            cells.push(
                _this.ConfigChangesPopupBlock(AsnIpv4Changes, "AsnIpv4ChangesId")
            )
        }
        if (key === "asnv6_config_changes") {
            AsnIpv6Changes = popupData[key];
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "IPv6 and AS changes"), id: "AsnIpv6ChangesId"}
            );
            cells.push(
                _this.ConfigChangesPopupBlock(AsnIpv6Changes, "AsnIpv6ChangesId")
            );
        }
        if (key === "asn_dscp_config_changes") {
            AsnDscpChanges = popupData[key]
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "AS and DSCP changes"), id: "AsnDscpChangesId"}
            );
            cells.push(
                _this.ConfigChangesPopupBlock(AsnDscpChanges, "AsnDscpChangesId"),
            )
        }
    }

    let popupBlocks = [];

    let popupTabbar = {
        view: "tabbar",
        id: "tabbar",
        multiview: true,
        options: options
    };
    let popupTabbarData = {
        cells: cells
    };

    popupBlocks.push(popupTabbar, popupTabbarData);

    return popupBlocks;
}

UsersActionsLogViewController.prototype.GetTariffPlansPopupBlocks = function (Item)
{
    let _this = this;

    let popupData = JSON.parse(Item.differences);

    let
        basicInfo = [],
        configInfo,
        basicInfoChanges,
        configInfoChanges,
        scheduleInfoChanges;

    for (let key in popupData) {
        if (key === "title") {
            basicInfo[TariffPlanList[key]] = popupData[key];
        }
        if (key === "status") {
            basicInfo[TariffPlanList[key]] = TariffPlanStateList[popupData[key]];
        }
        if (key === "individual") {
            basicInfo[TariffPlanList[key]] = TariffPlanTypeList[popupData[key]];
        }
        if (key === "schedule_enable") {
            basicInfo[TariffPlanList[key]] = TariffPlanList[popupData[key]];
        }
        if (key === "config") {
            configInfo = popupData[key];
        }
        if (key === "basic_info_changes") {
            basicInfoChanges = popupData[key]
        }
        if (key === "config_changes") {
            configInfoChanges = popupData[key];
        }
        if (key === "schedule_changes") {
            scheduleInfoChanges = popupData[key]
        }
    }

    let popupBlocks = [];

    popupBlocks.push(_this.BasicInfoPopupBlock(basicInfo))

    let
        options,
        cells

    if (scheduleInfoChanges && basicInfoChanges) {
        options = [
            {value: LangHelper.GetString("UsersActionsLog", "Configuration changes"), id: "BasicInfoChanges"},
            {value: LangHelper.GetString("UsersActionsLog", "Tariff schedule changes"), id: "ScheduleChanges"},
        ]
        cells = [
            _this.BasicInfoChangesPopupBlock(basicInfoChanges, Item.type_code, "BasicInfoChanges"),
            _this.ScheduleChangesPopupBlock(scheduleInfoChanges, "ScheduleChanges"),
        ]
    } else if (configInfoChanges) {
        options = [
            {value: LangHelper.GetString("UsersActionsLog", "Configuration changes"), id: "ConfigChanges"},
            {value: LangHelper.GetString("UsersActionsLog", "Current configuration"), id: "CurConfig"}
        ]
        cells = [
            _this.ConfigChangesPopupBlock(configInfoChanges, "ConfigChanges"),
            _this.CodeMirrorPopupBlock(configInfo, "CurConfig")
        ]
    } else if (basicInfoChanges) {
        options = [
            {value: LangHelper.GetString("UsersActionsLog", "Configuration changes"), id: "BasicInfoChanges"},
        ]
        cells = [
            _this.BasicInfoChangesPopupBlock(basicInfoChanges, Item.type_code, "BasicInfoChanges"),
        ]
    } else if (scheduleInfoChanges) {
        options = [
            {value: LangHelper.GetString("UsersActionsLog", "Tariff schedule changes"), id: "ScheduleChanges"},
        ]
        cells = [
            _this.ScheduleChangesPopupBlock(scheduleInfoChanges, "ScheduleChanges"),
        ]
    } else {
        options = [
            {value: LangHelper.GetString("UsersActionsLog", "Current configuration"), id: "CurConfig"}
        ]
        cells = [
            _this.CodeMirrorPopupBlock(configInfo, "CurConfig")
        ]
    }

    let popupTabbar = {
        view: "tabbar",
        id: "tabbar",
        multiview: true,
        options: options
    };
    let popupTabbarData = {
        cells: cells
    };

    popupBlocks.push(popupTabbar, popupTabbarData)

    return popupBlocks
}

UsersActionsLogViewController.prototype.GetBlackWhiteListsPopupBlocks = function (Item)
{
    let _this = this;

    let popupData = JSON.parse(Item.differences);

    let popupBlocks = [];

    let
        basicInfo = [],
        basicInfoChanges,
        syncTimeChanges,
        taskItemsChanges,
        taskItemsImportFromQoe;
    let
        options = [],
        cells = [];

    for (let key in popupData) {
        if (key === "title") {
            basicInfo[BlackWhiteList[key]] = popupData[key];
        }
        if (key === "list_type") {
            basicInfo[BlackWhiteList[key]] = BlackWhiteList[popupData[key]];
        }
        if (key === "redirect") {
            basicInfo[BlackWhiteList[key]] = popupData[key];
        }
        if (key === "federal") {
            basicInfo[BlackWhiteList[key]] = Boolean(popupData[key]);
        }
    }

    popupBlocks.push(_this.BasicInfoPopupBlock(basicInfo))

    for (let key in popupData) {
        if (key === "basic_info_changes") {
            basicInfoChanges = popupData[key];
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "Configuration changes"), id: "BasicInfoChanges"}
            );
            cells.push(
                _this.BasicInfoChangesPopupBlock(basicInfoChanges, Item.type_code, "BasicInfoChanges")
            );
        }
        if (key === "sync_time_changes") {
            syncTimeChanges = popupData[key];
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "Synchronization changes"), id: "SynchronizationChanges"}
            );
            cells.push(
                _this.BasicInfoChangesPopupBlock(syncTimeChanges, Item.type_code, "SynchronizationChanges")
            );
        }
        if (key === "task_items_changes") {
            taskItemsChanges = popupData[key];
            if (Object.keys(taskItemsChanges.delete).length > this.maxDataGridRows - 1) {
                options.push(
                    {value: LangHelper.GetString("UsersActionsLog", "Resources list changes") +
                            " / " + LangHelper.GetString("UsersActionsLog", "deleted over") + " " + this.maxDataGridRows + " " + LangHelper.GetString("UsersActionsLog", "resources"),
                            id: "TaskItemChanges"
                    }
                );
            } else {
                options.push(
                    {value: LangHelper.GetString("UsersActionsLog", "Resources list changes"), id: "TaskItemChanges"}
                );
            }
            cells.push(
                _this.TaskItemChangesPopupBlock(taskItemsChanges, "TaskItemChanges")
            );
        }
        if (key === "import_from_qoe") {
            taskItemsImportFromQoe = popupData[key];
            options.push(
                {value: LangHelper.GetString("UsersActionsLog", "Resources list changes"), id: _this + "TaskItemImportFromQoe"}
            );
            cells.push(
                _this.TaskItemImportFromQoe(taskItemsImportFromQoe, _this + "TaskItemImportFromQoe")
            );
        }
    }
    if (basicInfoChanges || syncTimeChanges || taskItemsChanges || taskItemsImportFromQoe) {
        let popupTabbar = {
            view: "tabbar",
            id: "tabbar",
            multiview: true,
            options: options
        };
        let popupTabbarData = {
            cells: cells
        };

        popupBlocks.push(popupTabbar, popupTabbarData);

    }

    return popupBlocks;
}

UsersActionsLogViewController.prototype.BasicInfoPopupBlock = function (Data)
{
    let content = '<div style="line-height: 2.3em">';

    for (let key in Data) {
        let value = Data[key]
        if (typeof value === "boolean") {
            content += `${LangHelper.GetString("UsersActionsLog", key)}: ${CellOnOffColoredTemplate(value)} <br>`
        } else {
            content += `${LangHelper.GetString("UsersActionsLog", key)}: ${LangHelper.GetString("UsersActionsLog", value)} <br>`
        }
    }

    content += '</div>';

    return {
        view: "template",
        autoheight: true,
        template: content,
    };
}

UsersActionsLogViewController.prototype.BasicInfoChangesPopupBlock = function (Data, TypeCode, Id)
{

    let content = '<div style="background-color:#FFEDED; line-height: 2em">'

    for (let key in Data.delete) {
        content += `- ${LangHelper.GetString("UsersActionsLog", ContentTypeCodeList[TypeCode][key])}: `
        if (key === "title" || key === "sync_time_hours" || key === "redirect") {
            content += `${Data.delete[key]} <br>`
        } else {
            content += `${LangHelper.GetString("UsersActionsLog", ContentTypeCodeList[TypeCode][Data.delete[key]])}<br>`
        }
    }

    content += '</div><div style="background-color:#EDFFEE; line-height: 2em">'

    for (let key in Data.add) {
        content += `+ ${LangHelper.GetString("UsersActionsLog", ContentTypeCodeList[TypeCode][key])}: `
        if (key === "title" || key === "sync_time_hours" || key === "redirect") {
            content += `${Data.add[key]} <br>`
        } else {
            content += `${LangHelper.GetString("UsersActionsLog", ContentTypeCodeList[TypeCode][Data.add[key]])}<br>`
        }
    }

    content += '</div>'

    return {
        id: Id,
        scroll: true,
        view: "template",
        template: content
    };
}

UsersActionsLogViewController.prototype.TaskItemChangesPopupBlock = function (Data, Id)
{
    let content_grid = [];

    let lang = LangHelper.GetLocale();

    let hostCategoryDic = App.DicsManager.GetDic(DicsEnum.HostCategoriesDic);
    let vaseHostCategoryDic = App.DicsManager.GetDic(DicsEnum.VaseListsDic);

    for (let item in Data.delete) {
        let uri_content;
        if (Data.delete[item].type === "clsfr") {
            uri_content = hostCategoryDic[Data.delete[item].uri].value;
        } else if (Data.delete[item].type === "vase") {
            let name_json = JSON.parse(vaseHostCategoryDic[Data.delete[item].uri].value);
            uri_content = name_json[lang];
        } else {
            uri_content = Data.delete[item].uri;
        }
        let values = {
            "status": "-",
            "enabled": `${LangHelper.GetString("UsersActionsLog", BlackWhiteListItems[Data.delete[item].enabled])}`,
            "type": `${Data.delete[item].type}`,
            "uri": uri_content,
        };
        content_grid.push(values);
    }

    for (let item in Data.add) {
        let uri_content;
        if (Data.add[item].type === "clsfr") {
            uri_content = hostCategoryDic[Data.add[item].uri].value;
        } else if (Data.add[item].type === "vase") {
            let name_json = JSON.parse(vaseHostCategoryDic[Data.add[item].uri].value);
            ur_content = name_json[lang];
        } else {
            uri_content = Data.add[item].uri;
        }
        let values = {
            "status": "+",
            "enabled": `${LangHelper.GetString("UsersActionsLog", BlackWhiteListItems[Data.add[item].enabled])}`,
            "type": `${Data.add[item].type}`,
            "uri": uri_content,
        };
        content_grid.push(values);
    }

    let block = {
        view:"datatable",
        id: Id,
        scheme:{
            $change:function(item){
                if (item.status === "-")
                    item.$css = "highlight_row_red";
                if (item.status === "+")
                    item.$css = "highlight_row_green"
            }
        },
        columns:[
            { id:"status", header:"", width:35},
            { id:"enabled", header:LangHelper.GetString("UsersActionsLog", "Status"), width:100},
            { id:"type", header:LangHelper.GetString("UsersActionsLog", "Type"), width:100},
            { id:"uri", header:LangHelper.GetString("UsersActionsLog", "Resource"), fillspace:true}
        ],
        data: content_grid,
    };

    return block;
}

UsersActionsLogViewController.prototype.TaskItemImportFromQoe = function (Data, Id)
{
    let content_grid = [];

    let hostCategoryDic = App.DicsManager.GetDic(DicsEnum.HostCategoriesDic);

    let catIds = Data.cat_id.split(",");

    for (let key in catIds) {
        let values = {
            "status": "+",
            "category": hostCategoryDic[catIds[key]].value,
            "limit": Data.limit,
        };
        content_grid.push(values);
    }

    let block = {
        view:"datatable",
        id: Id,
        scroll: true,
        scheme:{
            $change:function(item){
                if (item.status === "-")
                    item.$css = "highlight_row_red";
                if (item.status === "+")
                    item.$css = "highlight_row_green"
            }
        },
        columns:[
            { id:"status", header:"", width:35},
            { id:"category", header:LangHelper.GetString("UsersActionsLog", "Category"), fillspace:true},
            { id:"limit", header:LangHelper.GetString("UsersActionsLog", "Resources limit"), fillspace:true},
        ],
        data: content_grid,
    };

    return block;
}

UsersActionsLogViewController.prototype.ScheduleChangesPopupBlock = function (Data, Id)
{
    let content_grid = [];

    for (let item in Data.delete) {
        let values = {
            "status": "-",
            "time": `${Data.delete[item].startTime} - ${Data.delete[item].endTime}`,
            "tariff": `${Data.delete[item].selectedTariff}`,
            "weekday": ""
        };
        Object.values(Data.delete[item].weekday).forEach(value => {
            values.weekday += DaysOfWeekDic[value].value + " "
        })
        content_grid.push(values);
    }

    for (let item in Data.add) {
        let values = {
            "status": "+",
            "time": `${Data.add[item].startTime} - ${Data.add[item].endTime}`,
            "tariff": `${Data.add[item].selectedTariff}`,
            "weekday": ""
        };
        Object.values(Data.add[item].weekday).forEach(value => {
            values.weekday += DaysOfWeekDic[value].value + " "
        })
        content_grid.push(values);
    }

    let block = {
        view:"datatable",
        id: Id,
        scroll: true,
        scheme:{
            $change:function(item){
                if (item.status === "-")
                    item.$css = "highlight_row_red";
                if (item.status === "+")
                    item.$css = "highlight_row_green"
            }
        },
        columns:[
            { id:"status", header:"", width:35},
            { id:"time", header:LangHelper.GetString("Tariffs", "Time"), width:120},
            { id:"tariff", header:LangHelper.GetString("Tariffs", "Tariff"), fillspace:true},
            { id:"weekday", header:LangHelper.GetString("Tariffs", "Days of the week"), fillspace:true}
        ],
        data: content_grid,
    };


    return block;
}

UsersActionsLogViewController.prototype.ConfigChangesPopupBlock = function (Data, Id)
{
    let content = '<div style="background-color:#FFEDED; line-height: 2em">'

    for (let key in Data.delete) {
        let value = Data.delete[Number(key)]
        content += `- ${value} <br>`
    }

    content += '</div><div style="background-color:#EDFFEE; line-height: 2em">'

    for (let key in Data.add) {
        let value = Data.add[Number(key)]
        content += `+ ${value} <br>`
    }

    content += '</div>'

    return {
        id: Id,
        scroll: true,
        view: "template",
        template: content
    };
}

UsersActionsLogViewController.prototype.CodeMirrorPopupBlock = function (Data, Id)
{
    return {
        id: Id,
        view: "codemirror-editor",
        borderless: false,
        value: Data
    }
};

UsersActionsLogViewController.prototype.SubscribersContentPopup = function (Item) {
    const diff = JSON.parse(Item.differences);

    if(Item.action === "search"){
        return this.SearchSubscribersContentPopup(diff);
    }

    let mainContent = '<div style="line-height: 2em">';

    mainContent += `${LangHelper.GetString("UsersActionsLog", SubscribersFields[diff.identity_type])}: ${LangHelper.GetString("UsersActionsLog", diff.title)} <br>`;
    if(diff.ip)
        mainContent += `${LangHelper.GetString("UsersActionsLog", SubscribersFields["ip"])}: ${LangHelper.GetString("UsersActionsLog", diff.ip)} <br>`;

    mainContent += '</div>';

    const options = {
        "edited": {
            "background": ['<div style="background-color:#EDFFEE; line-height: 2em">', '<div style="background-color:#FFEDED; line-height: 2em">']
        },
        "created": {
            "background": ['<div style="background-color:#EDFFEE; line-height: 2em">']
        },
        "deleted": {
            "background": ['<div style="background-color:#FFEDED; line-height: 2em">']
        },
        "subscribers auth changed": {
            "background": ['<div style="background-color:#EDFFEE; line-height: 2em">']
        },
        "subscribers L2-props deleted": {
            "background": ['<div style="background-color:#FFEDED; line-height: 2em">']
        },
        "subscribers L2-props edited": {
            "background":  ['<div style="background-color:#EDFFEE; line-height: 2em">', '<div style="background-color:#FFEDED; line-height: 2em">']
        },
    }

    if(diff.fieldsDiff.services_profiles && Array.isArray(diff.fieldsDiff.services_profiles.add) && Array.isArray(diff.fieldsDiff.services_profiles.delete)){
        delete diff.fieldsDiff.services_profiles;
    }
    if(diff.fieldsDiff.services && Array.isArray(diff.fieldsDiff.services.add) && Array.isArray(diff.fieldsDiff.services.delete) &&
        diff.fieldsDiff.services.add[0] === "" && diff.fieldsDiff.services.delete[0] === ""){
        delete diff.fieldsDiff.services;
    }

    if(diff.fieldsDiff.policing && (diff.fieldsDiff.policing.add === "" || diff.fieldsDiff.policing.add === "0")
        && (diff.fieldsDiff.policing.delete === "" || diff.fieldsDiff.policing.delete === "0")){
        delete diff.fieldsDiff.policing;
    }

    let content = "";
    for (let elem in diff.fieldsDiff)
    {
        content += `${LangHelper.GetString("UsersActionsLog", SubscribersFields[elem])}: <br>`;
        let operator = "";
        let opt = "";
        for (const option in options)
        {
            if(Item.action == option)
            {
                for (const background of options[option].background)
                {
                    if(background === '<div style="background-color:#EDFFEE; line-height: 2em">')
                    {
                        operator = "+";
                        opt = "add";
                    } else {
                        operator = "-";
                        opt = "delete";
                        if(Item.action === 'deleted' || Item.action === "subscribers L2-props deleted" ){
                            opt = "add";
                        }
                    }

                    content += background;

                    if(Array.isArray(diff.fieldsDiff[elem][opt]) && diff.fieldsDiff[elem][opt].length && diff.fieldsDiff[elem][opt][0] !== "")
                    {
                        diff.fieldsDiff[elem][opt].forEach(item =>
                        {
                            if(elem === "services")
                            {
                                content += `${operator} ${ServicesDic[item] ? ServicesDic[item].value : LangHelper.GetString("UsersActionsLog", "Could not determine the specified service")} <br>`;
                            }
                        })
                        content += '</div>'
                    } else if (typeof diff.fieldsDiff[elem][opt] === 'object') {
                        for (const key in diff.fieldsDiff[elem][opt])
                        {
                            if(elem === "services_profiles")
                            {
                                const dic = App.DicsManager.GetDic(ServicesDicsEnum[key]);

                                content += `${operator} ${ServicesDic[key] ? ServicesDic[key].value : LangHelper.GetString("UsersActionsLog", "Could not determine the specified service")}:`;
                                content += ` ${dic[diff.fieldsDiff[elem][opt][key]] ? dic[diff.fieldsDiff[elem][opt][key]].value : LangHelper.GetString("UsersActionsLog", "Could not determine the specified profile")} <br>`;

                            }
                        }
                    } else {
                        if(elem === "policing")
                        {
                            const dic = App.DicsManager.GetDic(DicsEnum.PolicingProfilesDic);

                            content += `${operator} ${dic[diff.fieldsDiff[elem][opt]] ? dic[diff.fieldsDiff[elem][opt]].value : (diff.fieldsDiff[elem][opt] === "" || diff.fieldsDiff[elem][opt] === "0" ? LangHelper.GetString("Subscribers","No tariff") : LangHelper.GetString("Dics", "Personal tariff"))}`;
                        } else if (elem === "auth_status")
                        {
                            if(SubscribersAuthStatusesSetDic[diff.fieldsDiff[elem][opt]]){
                                content += `${operator} ${SubscribersAuthStatusesSetDic[diff.fieldsDiff[elem][opt]].value}`
                            }
                        } else if (elem === "type") {
                            if(SubscriberAuthSessionTypeDic[diff.fieldsDiff[elem][opt]]) {
                                content += `${operator} ${SubscriberAuthSessionTypeDic[diff.fieldsDiff[elem][opt]].value}`
                            } else {
                                content += `${operator} ${diff.fieldsDiff[elem][opt]}`
                            }
                        } else if (elem === "status") {
                            if(SubscriberAuthSessionStatusDic[diff.fieldsDiff[elem][opt]]) {
                                content += `${operator} ${SubscriberAuthSessionStatusDic[diff.fieldsDiff[elem][opt]].value}`
                            } else {
                                content += `${operator} ${diff.fieldsDiff[elem][opt]}`
                            }
                        }
                        else if (elem === "disable_local_connect") {
                            if(YesNoDic[diff.fieldsDiff[elem][opt]]) {
                                content += `${operator} ${YesNoDic[diff.fieldsDiff[elem][opt]].value}`
                            } else {
                                content += `${operator} ${diff.fieldsDiff[elem][opt]}`
                            }
                        }
                        else if (elem === "expired") {
                            let date = new Date(diff.fieldsDiff[elem][opt]);
                            let format = webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                            if(diff.fieldsDiff[elem][opt]) {
                                content += `${operator} ${diff.fieldsDiff[elem][opt] === "n/a" ? diff.fieldsDiff[elem][opt] : format(date)}`;
                            } else {
                                content += `${operator} ${diff.fieldsDiff[elem][opt] === "n/a" ? diff.fieldsDiff[elem][opt] : format(date)}`
                            }
                        }
                        else {
                            if(diff.fieldsDiff[elem][opt]){
                                content += `${operator} ${diff.fieldsDiff[elem][opt]}`;
                            }
                        }
                    }
                    content += '</div>'
                }
            }

            if(Item.action === option)
                break;
        }
    }

    return [
        {
            view: "template",
            autoheight: true,
            template: mainContent
        },
        {
            view: "tabbar",
            id: "tabbar",
            multiview: true,
            options: [
                {value: LangHelper.GetString("UsersActionsLog", "Subscriber changes"), id: 'SubscribersInfoChanges'},
            ]
        },
        {
            id: "SubscribersInfoChanges",
            scroll: true,
            view: "template",
            template: content
        },
    ];
};

UsersActionsLogViewController.prototype.SearchSubscribersContentPopup = function (Diff) {

    let content_grid = [];
    Diff = Diff.fieldsDiff;

    for (let elem in Diff)
    {
        let values = {};
        values.column = "";
        values.value = "";
        if(elem === "services") {
            values.column = `<b>${LangHelper.GetString("UsersActionsLog", SubscribersFields[elem])} </b><br>`;
            let services = Diff[elem].split(",");

            services.forEach(item => {
                values.value = `${ServicesDic[item] ? ServicesDic[item].value : (item === "NULL" ? LangHelper.GetString("Subscribers", "No services") : LangHelper.GetString("UsersActionsLog", "Could not determine the specified service"))} <br>`;
                content_grid.push(values);
                values = {};
                values.value = "";
            })
        } else if(elem === "services_profiles") {
            values.column = `<b>${LangHelper.GetString("UsersActionsLog", SubscribersFields[elem])} </b><br>`;
            for (const key in Diff[elem]){
                const dic = App.DicsManager.GetDic(ServicesDicsEnum[key]);

                values.value = `<b>${ServicesDic[key] ? ServicesDic[key].value : LangHelper.GetString("UsersActionsLog", "Could not determine the specified service")}:</b>`;
                let arr = Diff[elem][key].split(",");
                content_grid.push(values);
                values = {};
                values.value = "";

                for (const itemArr of arr) {
                    if(itemArr){
                        values.value = `${dic[itemArr] ? dic[itemArr].value : (itemArr === "null" ? LangHelper.GetString("Subscribers", "No profile") : LangHelper.GetString("UsersActionsLog", "Could not determine the specified profile"))} <br>`
                        content_grid.push(values);
                        values = {};
                        values.value = "";
                    }
                }
            }
        } else if(elem === "policing") {
            values.column = `<b>${LangHelper.GetString("UsersActionsLog", SubscribersFields[elem])} </b><br>`;
            const dic = App.DicsManager.GetDic(DicsEnum.PolicingProfilesDic);
            let policingArr = Diff[elem].split(",");

            for (const policing in policingArr) {
                values.value = `${dic[policingArr[policing]] ? dic[policingArr[policing]].value : (policing === "0" || policing === "" ? LangHelper.GetString("Subscribers","No tariff") : LangHelper.GetString("Dics", "Personal tariff"))} <br>`;
                content_grid.push(values);
                values = {};
                values.value = "";
            }
        }
        else {
            values.column = `<b>${LangHelper.GetString("UsersActionsLog", SubscribersFields[elem])} </b>`;

            if (elem === "auth_status")
            {
                if(SubscribersAuthStatusesDic[Diff.auth_status]){
                    values.value = `${SubscribersAuthStatusesDic[Diff.auth_status].value}`
                }
            } else if (elem === "type") {
                if(SubscriberAuthSessionTypeDic[Diff.type]) {
                    values.value = `${SubscriberAuthSessionTypeDic[Diff.type].value}`
                } else {
                    values.value = `${Diff.type}`
                }
            } else if (elem === "status") {
                if (SubscriberAuthSessionStatusDic[Diff.status]) {
                    values.value = `${SubscriberAuthSessionStatusDic[Diff.status].value}`
                } else {
                    values.value = `${Diff.status}`
                }
            } else if (elem === "bind_type") {
                if (BindTypesDic[Diff.bind_type]) {
                    values.value = `${BindTypesDic[Diff.bind_type].value}`
                } else {
                    values.value = `${Diff.status}`
                }
            } else {
                if(elem){
                    values.value = `${Diff[elem]}`;
                }
            }
            content_grid.push(values);
        }
    }

    return [
        {
            view: "tabbar",
            id: "tabbar",
            multiview: true,
            options: [
                {value: LangHelper.GetString("All", "Search"), id: 'SubscribersSearch'},
            ]
        },
        {
            view:"datatable",
            id: "SubscribersSearch",
            columns:[
                { id:"column", header:LangHelper.GetString("UsersActionsLog", "Column"), fillspace:true},
                { id:"value", header:LangHelper.GetString("DpiInfo", "Value"), fillspace:true}
            ],
            data: content_grid,
        }
    ];
}